<template>
  <div class="text-box">
    <div class="content" :class="{collapsed}" lang="en">
      <slot></slot>
    </div>
    <div class="mt-2" v-if="exceeded">
      <b-link @click="collapsed = !collapsed">{{collapsed ? '展开' : '收起'}}</b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'textBox',
  data() {
    return {
      collapsed: false,
      exceeded: false
    }
  },
  mounted() {
    const {lineHeight} = getComputedStyle(this.$el)
    if (this.$el.offsetHeight >= parseFloat(lineHeight) * 8) {
      this.exceeded = true
      this.collapsed = true
    }
  }
}
</script>

<style lang="scss" scoped>
.text-box {
  .content {
    white-space: pre-wrap;
    hyphens: auto;
    &.collapsed {
      @include line-clamp(5);
      overflow: hidden;
    }
  }
}
</style>
