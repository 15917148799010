<template>
  <div class="promotion">
    <form class="adding" @submit.prevent="submit" v-if="adding">
      <p>
        <avatar :src="user.avatar" size="1.5em"/>
        {{user.nickname}}

        <b-link class="float-right" @click="$router.back()">
          <fa icon="chevron-left"/>
          返回
        </b-link>
      </p>

      <b-form-group label="参赛照片（需满 9 图，拖动可重新排序）">
        <upload-zone v-model="newItem.images" cols="3" hide-exceeded draggable/>
      </b-form-group>
      <b-form-group label="参赛照片说明">
        <textarea class="form-control" rows="5" v-model="newItem.content" v-autosize
                  maxlength="1000" placeholder="说一说的参赛作品背后的故事"/>
      </b-form-group>

      <b-form-group label="照片拍摄日期">
        <b-input type="date" v-model="newItem.date" :max="$day().format('YYYY-MM-DD')"/>
      </b-form-group>

      <b-form-group label="照片拍摄的地点">
        <address-picker v-model="newItem.location"
                        placeholder="具体拍摄地点（如景点名、建筑名、街道名)"
                        input-type="input"
                        ignore-district/>
      </b-form-group>

      <b-btn block variant="primary" type="submit" :disabled="!canSubmit || submitting">发布</b-btn>
      <b-btn block v-if="myItem" @click="deleteSelf">删除参赛作品</b-btn>
    </form>
    <template v-else>
      <header>
        <avatar :src="user.avatar" size="20%" @dblclick.native="exportCsv"/>
        <div class="rule">
          <div class="mb-1">集满<b>10个赞</b>即可获得实物相框</div>
          <div>每个城市排名前10的用户可获得<b>免费微信书</b></div>
          <div>...</div>
          <div class="mt-2">
            <b-link v-b-modal="'rules'">查看详细规则</b-link>
          </div>
        </div>

        <b-modal title="活动细则" id="rules" hide-footer>
          <ol class="pl-3">
            <li>每人仅限参赛一次，上传一组作品，并且添加作品描述，可发到朋友圈求赞。</li>
            <li>参赛作品获得10个以上「赞」，即可领取朋友圈订制相框一份（价值48元）。</li>
            <li>大于20人参赛的城市，点赞排名前10的作品，可获得价值188元精装100页微信书一本（全国限量100本，赞高者优先获得）</li>
            <li>活动赠送产品需付12元邮费领取，全国统一</li>
            <li>活动时间为10月1日-10月15日（即16日零时截止），活动结束后将会进行评选</li>
          </ol>
          本活动解释权归杭州心书网络所有
        </b-modal>
      </header>

      <loading v-if="loading"/>
      <div class="items" v-else>
        <section v-if="hasEnd">
          <b-row align-v="center" class="text-muted" v-if="!myItem">
            <b-col cols="auto">
              <div class="icon">
                <fa icon="camera" fad/>
              </div>
            </b-col>
            <b-col>
              <b style="font-size: 1.25em;">活动已于 {{$day(endTime).format('LL LT')}} 结束</b>
            </b-col>
          </b-row>
          <b-row align-v="center" class="text-muted" v-else-if="myItem.likes.length < 10">
            <b-col cols="auto">
              <div class="icon">
                <fa icon="camera" fad/>
              </div>
            </b-col>
            <b-col>
              <b>活动已结束，您的作品未满10赞无法获得免单券哦</b>
              <br>
              <b-link @click="finish" :disabled="processing">
                {{processing ? '请稍候...' : '点此领取相框'}}
              </b-link>
            </b-col>
          </b-row>
          <b-link @click="finish" v-else :disabled="processing">
            <b-row align-v="center">
              <b-col cols="auto" class="text-primary">
                <div class="icon bg-primary">
                  <fa icon="gift" fad/>
                </div>
              </b-col>
              <b-col>
                <b v-if="processing">正在操作，请稍候...</b>
                <b v-else>您的作品已满10赞，点此领取相框及免单券</b>
              </b-col>
            </b-row>
          </b-link>
        </section>
        <section v-else-if="!myItem || myItem.uid !== uid">
          <b-link to="#add" :disabled="hasEnd">
            <b-row align-v="center">
              <b-col cols="auto">
                <div class="icon">
                  <fa icon="camera" fad/>
                </div>
              </b-col>
              <b-col>
                <b style="font-size: 1.25em;">我要参加比赛</b>
              </b-col>
            </b-row>
          </b-link>
        </section>
        <template v-if="itemsAll.length">
          <section v-for="item in itemsAll" :key="item.objectId" :class="{my: item.uid === uid}">
            <b-row>
              <b-col cols="auto">
                <avatar :src="item.avatar" size="3rem" rounded/>
              </b-col>
              <b-col>
                <div class="header">
                  <template v-if="item.uid === uid">
                    <b-btn class="float-right mt-n1"
                           size="sm"
                           variant="primary"
                           pill
                           @click="posterActive = true">
                      <fa icon="gift" fad/>
                      求赞赢相框
                    </b-btn>
                    <b>你自己</b>
                  </template>
                  <template v-else>
                    <b>
                      <fa icon="star" v-if="item.sticky" fas class="text-warning"/>
                      {{item.nickname}}
                    </b>
                    <div class="float-right">
                      <b-link @click="viewPoster(item)">查看TA的相框</b-link>
                    </div>
                  </template>
                </div>

                <text-box class="my-2">{{item.content}}</text-box>

                <div class="text-muted mb-2 clearfix">
                  拍摄于
                  {{item.date}}
                  <div class="float-right" v-if="item.location" style="color: #285F89">
                    <fa icon="map-marker-alt"/>
                    {{getLocation(item.location)}}
                  </div>
                </div>

                <div style="padding-right: 4rem;">
                  <image-zone :value="item.images" cols="3" gutter="4"/>
                </div>

                <footer>
                  <span class="text-muted">
                    发布于
                    <datetime :value="item.createdAt"/>
                  </span>

                  <div class="float-right">
                    <b-link v-if="item.uid === uid" @click="editMy">
                      <fa icon="pencil"/>
                      编辑
                    </b-link>
                    <b-link disabled v-else-if="item.likes.includes(uid)">已点赞</b-link>
                    <b-link v-else-if="!ended" @click="like(item)">
                      <fa icon="thumbs-up"/>
                      点赞
                    </b-link>
                    <b class="ml-2">{{item.likes.length || 0}} 赞</b>
                  </div>
                </footer>
              </b-col>
            </b-row>
          </section>

          <divider v-if="ended">没有更多了</divider>
          <b-btn block @click="loadMore" v-else :disabled="fetching">加载更多</b-btn>
        </template>
        <template v-else>
          <divider>还没有人参加比赛哦</divider>
        </template>
      </div>

      <popup @close="posterActive = false" :src="poster" v-if="posterActive"
             guide="保存图片分享给好友吧"/>

      <popup :src="posterOther" v-if="posterOther" @close="posterOther = ''"></popup>
    </template>
  </div>
</template>

<script>
import lean from '@/models/leancloud'
import UploadZone from '@/components/UploadZone'
import ImageZone from '@/components/ImageZone'
import TextBox from '@/components/TextBox'
import { pick, uniq } from 'lodash'
import { configShare } from '@/modules/wechat'
import AddressPicker from '@/components/AddressPicker'

export default {
  name: 'nationalDay2020',
  components: {AddressPicker, TextBox, ImageZone, UploadZone},
  title: '2020朋友圈摄影大赛',
  data() {
    return {
      page: 1,
      pageSize: 50,
      loading: false,
      fetching: false,
      ended: false,
      myItem: null,
      topItem: null,
      submitting: false,
      posterActive: false,
      processing: false,
      posterOther: '',
      items: [],
      endTime: '2020-10-15T16:00:00.000Z',
      newItem: {
        content: '',
        images: [],
        date: '2020-01-01',
        location: ''
      }
    }
  },
  computed: {
    adding() {
      return ['#add', '#edit'].includes(this.$route.hash)
    },
    hasEnd() {
      return new Date() > new Date(this.endTime)
    },
    uid() {
      return this.user?.openid
    },
    canSubmit() {
      return this.newItem.images.length && !!this.newItem.content
    },
    itemsAll() {
      return [this.topItem, this.myItem, ...this.items].filter(Boolean)
    },
    poster() {
      return this.getPoster(this.myItem)
    }
  },
  watch: {
    newItem: {
      deep: true,
      handler(val) {
        const newItem = pick(val, ['content', 'images', 'date', 'location'])
        this.$ls.set(this.$route.name + '.newItem', newItem)
      }
    }
  },
  async created() {
    const queryUid = this.$route.query.uid
    const cache = this.$ls.get(this.$route.name + '.newItem')
    if (cache) {
      this.newItem = Object.assign({}, this.newItem, cache)
    }
    try {
      this.loading = true
      await this.loadMore()

      this.myItem = await lean.get('/classes/National2020', {
        params: {
          where: `{"uid":"${this.uid}"}`,
          limit: 1
        }
      }).then(i => i[0])

      if (queryUid) {
        this.topItem = await lean.get('/classes/National2020', {
          params: {
            where: `{"uid":"${queryUid}"}`,
            limit: 1
          }
        }).then(i => i[0])

        if (this.topItem) {
          this.topItem.sticky = true
        }

        if (this.topItem?.uid === this.myItem?.uid) {
          this.topItem = null
        }
      }
    } finally {
      this.loading = false
    }

    configShare({
      title: '2020朋友圈摄影大赛' + (this.myItem ? `${this.myItem.nickname}的参赛作品` : ''),
      desc: this.myItem ? '帮忙给我的作品点赞，赢取朋友圈相框' : '上传自己的摄影作品，赢取朋友圈相框',
      imgUrl: 'https://img.xinshu.me/upload/ddd0a73b161344678bd64fc41a3fbadd',
      link: 'https://weixinshu.com/promotions/national-2020?uid=' + this.user.openid
    })
  },
  methods: {
    async finish() {
      try {
        this.processing = true

        if (this.myItem.likes.length >= 10) {
          const couponNo = 'IBWGZA'
          const {status} = await this.$req.get(`/api/user/activity_coupons/${couponNo}/check`)
          if (status === 'UNCLAIMED') {
            await this.$req.get(`/api/user/activity_coupons/${couponNo}`)
          }
        }

        const albums = await this.$ajax.fetchAlbums({})
        const result = albums.find(i => i.name.includes('摄影大赛相框'))
        if (result) {
          return this.$router.push('/albums/' + result.aid)
        }

        const album = await this.$req.post('/jianxiang/api/1/albums/', {
          tid: 'frame-moment',
          innerStyle: {id: 'frame-moment-9p'},
          name: '2020朋友圈摄影大赛相框',
          meta: {
            author: this.myItem.nickname,
            avatar: this.myItem.avatar,
            date: this.myItem.date,
            city: this.myItem.location.split('|')[1],
            site: this.myItem.location.split('|')[3]
          }
        })

        const images = await this.$req.post(
          `/jianxiang/api/1/albums/${album.aid}/activities/`,
          {
            images: this.myItem.images,
            uploadOnly: true
          }
        )

        const page = {
          index: 0,
          name: '001',
          layers: [
            ...images.map(item => {
              return {
                id: item.pid,
                type: 'image'
              }
            }),
            {
              type: 'text',
              content: this.myItem.content
            }
          ]
        }

        await this.$req.post('/jianxiang/api/1/albums/' + album.aid + '/operation/', {
          action: 'set_pages',
          pages: [page]
        })

        this.$router.push('/albums/' + album.aid)
      } catch (err) {
        console.error(err)
        this.processing = false
      }
    },
    async loadMore() {
      try {
        this.fetching = true
        const results = await lean.get('/classes/National2020', {
          params: {
            order: '-createdAt',
            where: `{"uid":{"$nin":["${this.uid}","${this.$route.query.uid}"]}}`,
            skip: (this.page - 1) * this.pageSize,
            limit: this.pageSize
          }
        })
        this.page++
        this.items.push(...results)
        this.ended = results.length < this.pageSize
      } finally {
        this.fetching = false
      }
    },
    getLocation(address) {
      if (!address) {
        return ''
      }
      const [, city, , street] = address.split('|')
      return city + '·' + street
    },
    editMy() {
      this.newItem.content = this.myItem.content
      this.newItem.images = [...this.myItem.images]
      this.newItem.date = this.myItem.date
      this.newItem.location = this.myItem.location

      this.$router.push('#edit')
    },
    async submit() {
      const {content, images, date, location} = this.newItem

      if (!date) {
        this.$alert.error('请选择拍摄日期')
        return
      }

      if (new Date(date) > new Date()) {
        this.$alert.error('请选择早于今天的日期')
        return
      }

      if (images.length < 9) {
        this.$alert.error('需满 9 图才能参赛哦')
        return
      }

      if (!location) {
        this.$alert.error('请完整填写拍摄地点')
        return
      }

      try {
        this.submitting = true

        const data = {
          nickname: this.user.nickname,
          avatar: this.user.avatar,
          uid: this.uid,
          content,
          images,
          date,
          location
        }

        let result = null

        if (this.myItem) {
          result = await lean.put(`classes/National2020/${this.myItem.objectId}`, data)
          this.$alert.success('参赛内容已保存')
        } else {
          result = await lean.post('/classes/National2020', data)
          this.$alert.success('参赛成功')
        }

        this.myItem = await lean.get('classes/National2020/' + result.objectId)
        this.newItem = {
          content: '',
          images: [],
          date: '2020-01-01',
          location: ''
        }

        this.$ls.remove(this.$route.name + '.newItem')
        this.$router.back()
      } finally {
        this.submitting = false
      }
    },
    async like(item) {
      item.likes.push(this.uid)
      item.likes = uniq(item.likes)
      try {
        const result = await lean.put('/classes/National2020/' + item.objectId, {
          likes: {
            __op: 'AddUnique',
            objects: [this.uid]
          }
        }, {
          params: {
            fetchWhenSave: true
          }
        })
        item.likes = result.likes
        this.$alert.success('感谢您的支持')
      } catch (err) {
        item.likes = item.likes.filter(i => i !== this.uid)
      }
    },
    async deleteSelf() {
      if (!this.myItem) {
        return
      }
      const confirmed = await this.$dialog.confirm({
        title: '删除参赛作品',
        content: '是否要删除自己的参赛作品，删除后您可以重新发布新的作品，但所有点赞将会被清空'
      })
      if (!confirmed) {
        return
      }
      await lean.delete('classes/National2020/' + this.myItem.objectId)
      this.$alert.success('您的参赛作品已删除')
      this.myItem = null
      this.$router.back()
    },
    viewPoster(item) {
      this.posterOther = this.getPoster(item)
    },
    getPoster(item) {
      if (!item) {
        return ''
      }
      const [, city, , site] = item.location.split('|')
      const params = {
        author: item.nickname,
        avatar: item.avatar,
        month: new Date(item.date).getMonth() + 1,
        date: new Date(item.date).getDate(),
        text: item.content,
        city,
        site,
        uid: this.uid
      }
      if (params.location) {
        params.location = '📍 ' + params.location
      }
      item.images.forEach(({url}, i) => {
        params['pic-' + i] = url + '!960'
      })
      return 'https://canvas.xinshu.me/generate/national2020' + this.serialize(params)
    },
    async exportCsv() {
      this.$alert.success('正在导出，请稍候...')
      while (!this.ended) {
        await this.loadMore()
      }
      const csvLines = [
        ['用户', 'UID', '发布时间', '照片拍摄时间', '省市', '地点', '赞数', '内容']
      ]
      for (const item of this.items) {
        const line = [
          item.nickname,
          item.uid,
          this.$day(item.createdAt).format('LL LT'),
          this.$day(item.postedAt).format('LL LT'),
          item.location && item.location.split('|').slice(0, 2).join(' '),
          item.location && item.location.split('|').slice(-1)[0],
          item.likes.length.toString(),
          item.content.split('\n').join('\\n')
        ]
        csvLines.push(line)
      }
      const csv = csvLines.map(l => l.map(i => `"${(i || '').split('"').join('\'')}"`)).join('\n')
      const file = new Blob([csv], {type: 'text/csv'})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(file)
      link.download = 'stats.csv'
      link.click()
      URL.revokeObjectURL(file)
    }
  }
}
</script>

<style lang="scss" scoped>
.promotion {
  max-width: 560px;
  padding: 0;
  background-color: #fff;
  min-height: 100vh;

  header {
    position: relative;
    background-image: url(https://img.xinshu.me/upload/e03094cf036942098ec7b4e7b6311ed0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-color: #ffedcb;
    padding-bottom: 105%;

    .rule {
      position: absolute;
      width: 63%;
      top: 30%;
      height: 35%;
      padding: 1em;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      user-select: none;
    }

    .avatar {
      position: absolute;
      right: 1rem;
      bottom: -1rem;
      border: 2px solid $white;
    }
  }

  .items {
    padding: 1rem;
  }

  section {
    padding: 1rem 0;
    border-top: 1px solid $hr-border-color;

    &:first-of-type {
      border-top-color: transparent;
    }

    .icon {
      display: inline-block;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      text-align: center;
      background-color: #ccc;
      color: #fff !important;
      font-size: 1.5em;
    }

    .content {
      white-space: pre-wrap;
      hyphens: auto;
    }
  }

  .adding {
    padding: 1rem;
  }
}
</style>
