<template>
  <div class="address-picker">
    <slot name="title"></slot>

    <loading v-if="!areas"/>

    <template v-else>
      <b-row>
        <b-col>
          <b-form-group>
            <b-select :class="{active: province}" @change="onProvinceChange" title="省"
                      v-model="province">
              <option disabled value="">请选择省</option>
              <option :value="item" v-for="item in provinces" :key="item">{{item}}</option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col v-if="!isMunicipality">
          <b-form-group>
            <b-select :class="{active: city}" :disabled="!province" @change="district = ''"
                      title="市" v-model="city">
              <option disabled value="">市</option>
              <option :value="item" v-for="item in cities" :key="item">{{item}}</option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col v-if="!ignoreDistrict">
          <b-form-group>
            <b-select :class="{active: district}" :disabled="!city" title="区/县" v-model="district">
              <option disabled value="">区/县</option>
              <option :value="item" v-for="item in districts" :key="item">{{item}}</option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>

      <textarea class="form-control" id="address"
                maxlength="60" name="street"
                :placeholder="placeholder"
                rows="1" v-autosize v-model="street"></textarea>
    </template>
  </div>
</template>

<script>
import { chain, keys } from 'lodash'

export default {
  name: 'addressPicker',
  data() {
    return {
      areas: null,
      province: '',
      city: '',
      district: '',
      street: ''
    }
  },
  props: {
    value: {
      required: true
    },
    placeholder: {
      type: String,
      default: '道路、小区、门牌号、楼栋号、单元、房间号等信息'
    },
    ignoreDistrict: Boolean,
    cache: Boolean
  },
  created() {
    this.fetchAreas().then(data => {
      this.areas = data
    })
    const lsKey = 'address'
    const localAddress = this.$ls.get(lsKey)
    if (this.cache) {
      if (localAddress) {
        this.handleAddress(localAddress)
      }
      this.$watch('address', (val) => {
        this.$ls.set(lsKey, val)
      })
    }
    if (this.value) {
      this.handleAddress(this.value)
    }
  },
  watch: {
    value: 'handleAddress',
    address(value) {
      this.$emit('input', value)
    }
  },
  computed: {
    isMunicipality() {
      return /北京|上海|天津|重庆/.test(this.province)
    },
    address() {
      const address = [
        this.province,
        this.city,
        this.ignoreDistrict ? '城区' : this.district,
        this.street
      ]
      if (address.every(Boolean)) {
        return address.map(item => item.trim().split('|').join(' ')).join('|')
      }
      return ''
    },
    provinces() {
      if (this.areas) {
        return chain(this.areas).map(keys).flatten().value()
      }
      return []
    },
    cities() {
      if (!this.provinces) {
        return []
      }
      return chain(this.areas)
        .find(i => i[this.province])
        .get(this.province)
        .map(keys)
        .flatten()
        .value() || []
    },
    districts() {
      if (!this.cities) {
        return []
      }
      return chain(this.areas)
        .find(i => i[this.province])
        .get(this.province)
        .find(i => i[this.city])
        .get(this.city)
        .value() || []
    }
  },
  methods: {
    reset() {
      this.province = ''
      this.city = ''
      this.district = ''
      this.street = ''
    },
    handleAddress(val) {
      if (!val) {
        return
      }
      const address = val
      const addressParts = address.split('|')
      if (addressParts.length >= 4) {
        this.province = addressParts[0].replace(/^["']/, '')
        this.city = addressParts[1]
        this.district = addressParts[2]
        this.street = addressParts.slice(3).join('').replace(/["']$/, '')
        return
      }
      this.province = ''
      this.city = ''
      this.district = ''
      this.street = address
      this.$emit('input', '')
    },
    onProvinceChange() {
      this.city = ''
      this.district = ''
      if (this.isMunicipality) {
        // 直辖市县市一栏同省份
        this.city = this.province
      }
    },
    fetchAreas() {
      return this.$req.get('/buckets/weixinshu:areas')
    }
  }
}
</script>
